
export default {
  name: "Progress",
  props: {
    value: {
      type: String,
      default: "0",
    },
  },
};
