
import browserslist from "browserslist";
import { computed, defineComponent, ref, watchEffect } from "vue";

import Table from "./components/Table.vue";
import Progress from "./components/Progress.vue";
import { BrowserType, BrowserData, browserMap } from "./utils";
import versions from "./versions.json";

export default defineComponent({
  name: "App",
  components: { Table, Progress },
  setup() {
    const worker = ref<ServiceWorker>();
    const updateExists = ref(false);

    const browserString = ref(
      new URLSearchParams(window.location.search).get("q") || "defaults",
    );
    const browserData = ref([] as BrowserData[]);
    const queryError = ref(false);
    const totalCoverage = ref("0");

    const appendQueryToUrl = () => {
      if (!queryError.value) {
        history.replaceState(
          undefined,
          "Browserslist UI",
          "/?q=" + encodeURIComponent(browserString.value),
        );
      }
    };

    watchEffect(() => {
      let data: Record<string, BrowserData> = {};
      queryError.value = false;
      try {
        const result = browserslist(browserString.value);
        const usageData = browserslist.usage.global;
        data = result.reduce((acc: Record<string, BrowserData>, item) => {
          const coverage = usageData?.[item].toFixed(2) + "%" || "unknown";
          const [name, version] = item.split(" ");
          if (acc[name]) {
            acc[name].versions[version] = coverage;
          } else {
            let browser = browserMap[name];
            if (!browser) {
              browser = {
                name,
                id: name,
                icon: browserMap.unknown.icon,
                type: browserMap.unknown.type,
              };
            }
            acc[name] = {
              ...browser,
              versions: { [version]: coverage },
            };
          }
          return acc;
        }, {});
        totalCoverage.value = browserslist.coverage(result).toFixed(2);
      } catch (error) {
        queryError.value = true;
        totalCoverage.value = "0";
      }

      browserData.value = Object.values(data);
    });

    const desktopBrowsers = computed(() => {
      return browserData.value.filter(
        (browser: BrowserData) => browser.type === BrowserType.Desktop,
      );
    });

    const mobileBrowsers = computed(() => {
      return browserData.value.filter(
        (browser: BrowserData) => browser.type === BrowserType.Mobile,
      );
    });

    function showRefreshUI(event: CustomEvent<ServiceWorker>) {
      worker.value = event.detail;
      updateExists.value = true;
    }
    function refreshApp() {
      updateExists.value = false;
      if (!worker.value) {
        // eslint-disable-next-line no-console
        console.warn("No worker data found when trying to refresh!");
        return;
      }
      worker.value.postMessage({ type: "SKIP_WAITING" });
    }

    // @ts-expect-error FIXME
    document.addEventListener("worker-updated", showRefreshUI, {
      once: true,
    });

    return {
      browserString,
      queryError,
      totalCoverage,
      versions,
      appendQueryToUrl,
      desktopBrowsers,
      mobileBrowsers,

      showRefreshUI,
      refreshApp,
      updateExists,
    };
  },
});
