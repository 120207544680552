
import { BrowserData } from "../utils";

export default {
  name: "Table",
  props: {
    browser: {
      type: Object,
      default: {} as BrowserData,
    },
  },
};
